















































































































































































































































































































































































































































































































































.setup-phone-number-inner {
  .card-wrapper {
    width: 100%;
    max-width: 600px;
    margin: auto;
  }
  .logout-button {
    max-width: 200px;
    position: absolute;
    top: 50px;
    right: 50px;
  }
  .search-button {
    max-width: 200px;
    margin: auto;
  }
  .custom-control {
    z-index: 0;
  }
}
